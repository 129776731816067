import React from 'react'
import BrandLogo from '../../assets/placemakr-horizontal-logo-dusk.png'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faArrowRightFromBracket,
  faBan,
  faCaretDown,
  faCreditCard,
  faIdCard,
  faMoneyCheckDollar,
  faUser
} from '@fortawesome/free-solid-svg-icons'

const GuestNavBar = () => {
  return (
    <nav className="navbar bg-white border-b">
      <div className="flex-1">
        <a className="btn btn-ghost text-xl">
          <img src={BrandLogo} className="w-36" />
        </a>
      </div>
      <div className="flex justify-end flex-1">
        <div className="flex items-stretch">
          <div className="dropdown dropdown-end mr-4">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost rounded-btn flex items-center"
            >
              <span className="font-medium" style={{fontSize: 16}}>
                reservation
              </span>
              <FontAwesomeIcon icon={faCaretDown} style={{fontSize: 16}} />
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-72 mt-3"
            >
              <li>
                <Link to="/guest/billing">
                  <FontAwesomeIcon icon={faMoneyCheckDollar} className="w-4" />
                  billing
                </Link>
              </li>
              <li>
                <Link to="/guest/payment">
                  <FontAwesomeIcon icon={faCreditCard} className="w-4" />
                  payments
                </Link>
              </li>
              <li>
                <Link to="/guest/verification">
                  <FontAwesomeIcon icon={faIdCard} className="w-4" />
                  verification
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/guest/cancellation">
                  <FontAwesomeIcon icon={faBan} className="w-4" />
                  cancel reservation
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex-none">
        <div className="flex items-stretch">
          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-square mr-4 w-12 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faUser} className="text-xl text-primary" />
              <FontAwesomeIcon icon={faCaretDown} style={{fontSize: 16}} />
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-72 mt-3"
            >
              <li>
                <Link to="/signup">
                  <FontAwesomeIcon icon={faAddressCard} className="w-4" />
                  <span>become a member</span>
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/logout">
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="w-4"
                  />
                  <span>log out</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default GuestNavBar
