import React, {useState} from 'react'
import clsx from 'clsx'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {format, isAfter, isSameDay, isValid} from 'date-fns'
import {GuestCredentialsType} from '../../types'
import {useLoginMutation} from '../../services/guestCheckinApi'
import {getLogger} from '../../services/logging'
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import DatePickerInput from '../common/DatePickerInput'

export interface GuestLoginFormProps {
  onLoginSuccess: (token: string) => void
  onLoginFailure: () => void
}

interface GuestLoginFormState {
  firstName: string
  lastName: string
  phoneLast4: string
  checkinDate?: Date
  checkoutDate?: Date
  isAuthenticating?: boolean
}

const logger = getLogger('GuestLoginForm')

function validate(loginState: GuestLoginFormState): boolean {
  const {
    firstName,
    lastName,
    phoneLast4,
    checkinDate,
    checkoutDate
  } = loginState

  if (!firstName?.length) {
    return false
  }

  if (!lastName?.length) {
    return false
  }

  if (phoneLast4?.length !== 4) {
    return false
  }

  if (!isValid(checkinDate)) {
    return false
  }

  if (
    !isValid(checkoutDate) ||
    isSameDay(checkinDate, checkoutDate) ||
    !isAfter(checkoutDate, checkinDate)
  ) {
    return false
  }

  return true
}

export default function GuestLoginForm(props: GuestLoginFormProps) {
  const {onLoginSuccess, onLoginFailure} = props
  const [loginState, setLoginState] = useState<GuestLoginFormState>({
    firstName: '',
    lastName: '',
    phoneLast4: '',
    checkinDate: null,
    checkoutDate: null,
    isAuthenticating: false
  })
  const [login] = useLoginMutation()
  const isValid = validate(loginState)

  return (
    <div>
      <form className="flex flex-col gap-4 mt-6">
        <input
          type="text"
          placeholder="First name"
          className="w-full checkin-input"
          value={loginState.firstName}
          onChange={e => {
            const firstName = e.currentTarget.value.trim()
            setLoginState({...loginState, firstName})
          }}
        />

        <input
          type="text"
          placeholder="Last name"
          className="w-full checkin-input"
          value={loginState.lastName}
          onChange={e => {
            const lastName = e.currentTarget.value.trim()
            setLoginState({...loginState, lastName})
          }}
        />

        <input
          type="text"
          placeholder="Last 4 digits of phone number"
          className="w-full checkin-input"
          value={loginState.phoneLast4}
          onChange={e => {
            const value = e.currentTarget.value.trim()
            const phoneLast4 = value.length > 4 ? value.substring(0, 4) : value
            setLoginState({...loginState, phoneLast4})
          }}
        />

        <DatePickerInput
          value={loginState.checkinDate}
          onChange={(date: Date): void => {
            setLoginState({
              ...loginState,
              checkinDate: date
            })
          }}
          placeholder="Check-in date"
        />

        <DatePickerInput
          value={loginState.checkoutDate}
          onChange={(date: Date): void => {
            setLoginState({
              ...loginState,
              checkoutDate: date
            })
          }}
          placeholder="Check-out date"
        />
      </form>

      <button
        className="btn btn-secondary flex flex-row justify-center items-center w-full gap-2 mt-6"
        disabled={!isValid || loginState.isAuthenticating}
        onClick={() => {
          setLoginState({
            ...loginState,
            isAuthenticating: true
          })

          const credentials = {
            type: GuestCredentialsType.ReservationLookup,
            firstName: loginState.firstName,
            lastName: loginState.lastName,
            phoneLast4: loginState.phoneLast4,
            checkinDate: format(loginState.checkinDate, 'yyyy-MM-dd'),
            checkoutDate: format(loginState.checkoutDate, 'yyyy-MM-dd')
          }

          login(credentials)
            .unwrap()
            .then(({token}) => {
              onLoginSuccess(token)
            })
            .catch(e => {
              logger.warn({
                message: 'Login failure with reservation lookup',
                data: {credentials, error: e}
              })

              setLoginState({
                ...loginState,
                isAuthenticating: false
              })

              onLoginFailure()
            })
        }}
      >
        <div className={clsx({hidden: !loginState.isAuthenticating})}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
        <div className="text-center">
          <FontAwesomeIcon icon={faRightToBracket} className="mr-3" />
          {loginState.isAuthenticating ? 'logging in...' : 'log in'}
        </div>
      </button>
    </div>
  )
}
