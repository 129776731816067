import React, {useEffect} from 'react'
import {getLogger} from '../../services/logging'

interface VerifyIdentityFormProps {
  verificationUrl: string
  onNext: () => void
}

const logger = getLogger('VerifyIdentityForm')

const VerifyIdentityForm = ({
  verificationUrl,
  onNext
}: VerifyIdentityFormProps) => {
  useEffect(() => {
    if (!verificationUrl) {
      return
    }

    logger.info({
      message: 'Initializing message listener for Autohost guest portal iframe',
      data: {verificationUrl}
    })

    const verificationOrigin = new URL(verificationUrl).origin

    // Process guest portal events from iframe - see autohost docs:
    // https://docs.autohost.ai/docs/knowledge/integrations/embedding-the-guest-portal-using-iframe

    window.onmessage = (message: MessageEvent) => {
      try {
        if (message.origin === verificationOrigin) {
          const event = JSON.parse(message.data)

          if (event.type === 'guestportal-completed') {
            logger.info({
              message: 'Autohost guest portal completed',
              data: {event}
            })
            // advance to next step
            onNext()
          } else if (event.type === 'guestportal-step-completed') {
            logger.info({
              message: 'Autohost guest portal step completed',
              data: {event}
            })
          } else {
            logger.warn({
              message: `Autohost guest portal - unhandled event type: ${event.type}`,
              data: {event}
            })
          }
        }
      } catch (e) {
        logger.error({
          message: 'Autohost iframe message handler failed to process message',
          errorData: {error: e}
        })
      }
    }
  }, [verificationUrl])

  return (
    <section className="flex flex-col items-center gap-8 px-16 text-center mx-auto pt-12">
      <h1 className="header-2 font-semibold">Guest Verification</h1>

      {verificationUrl ? (
        <iframe
          src={`${verificationUrl}?embed=1&isMobile=yes&hide=logo,topbar,image`}
          allow="geolocation; camera; fullscreen;"
          width="100%"
          height="700"
          className="border border-solid mb-16 p-4"
        ></iframe>
      ) : (
        ''
      )}
    </section>
  )
}

export default VerifyIdentityForm
