import React from 'react'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPoo} from '@fortawesome/free-solid-svg-icons'

const NotFoundPage = () => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center gap-8">
      <FontAwesomeIcon icon={faPoo} className="text-9xl text-cabana" />
      <div className="text-center">
        <h2 className="text-4xl font-light">Oops, page not found!</h2>
      </div>
      <Link to="/" className="link">
        go back
      </Link>
    </div>
  )
}

export default NotFoundPage
