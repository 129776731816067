import React from 'react'
import {useMemberContext} from './MemberPage'
import {Link} from 'react-router-dom'

const MemberRatesPage = () => {
  const {user} = useMemberContext()

  return (
    <div className="p-8 flex flex-col gap-4">
      {/* Breadcrumb */}
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/member" className="link link-primary">
              Member
            </Link>
          </li>
          <li>Rates</li>
        </ul>
      </div>
      <h1 className="header-2">Member Rates</h1>
      <p>This is where the member rates goes...</p>
    </div>
  )
}

export default MemberRatesPage
