import React, {ReactNode, useEffect, useState} from 'react'
import {
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery
} from '../../services/guestCheckinApi'
import {getLogger} from '../../services/logging'
import {useGuestContext} from './GuestPage'
import {Link} from 'react-router-dom'
import PaymentForm from '../common/PaymentForm'
import {Reservation} from '../../types'
import LoadingScreen from '../common/LoadingScreen'
import CheckinCheckmark from '../../assets/checkin-checkmark.png'
import CheckinError from '../../assets/checkin-error.png'
import VerifyPaymentStatus from '../checkin/VerifyPaymentStatus'

const logger = getLogger('GuestPaymentPage')

enum ViewState {
  Form = 'Form',
  FormLoading = 'FormLoading',
  StatusLoading = 'StatusLoading',
  Complete = 'Complete',
  Error = 'Error'
}

interface GuestPaymentLayoutProps {
  children: ReactNode
}

function GuestPaymentLayout({children}: GuestPaymentLayoutProps) {
  return (
    <div className="px-8 py-4">
      {/* Breadcrumb */}
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/guest" className="link link-primary">
              Reservation
            </Link>
          </li>
          <li>Guest Payment</li>
        </ul>
      </div>

      <h2 className="header-2">Guest Payment</h2>

      <div className="my-8">{children}</div>
    </div>
  )
}

const GuestPaymentPage = () => {
  const {reservation} = useGuestContext()
  const [viewState, setViewState] = useState(ViewState.FormLoading)
  const paymentMethodsQuery = useGetPaymentMethodsQuery(null)
  const paymentSessionQuery = useGetPaymentSessionQuery(null)
  const paymentSessionId = paymentSessionQuery.data?.id

  useEffect(() => {
    if (reservation?.paymentAccount?.isActive) {
      setViewState(ViewState.Complete)
    } else if (
      paymentMethodsQuery.isLoading ||
      !paymentMethodsQuery.data ||
      paymentSessionQuery.isLoading ||
      !paymentSessionQuery.data
    ) {
      setViewState(ViewState.FormLoading)
    } else if (paymentMethodsQuery.data && paymentSessionQuery.data) {
      setViewState(ViewState.Form)
    } else {
      setViewState(ViewState.Error)
    }
  }, [reservation, paymentMethodsQuery.data, paymentSessionQuery.data])

  console.log('DEBUG', {
    viewState,
    paymentMethodsQuery,
    paymentSessionQuery
  })

  if (viewState === ViewState.FormLoading) {
    return (
      <GuestPaymentLayout>
        <LoadingScreen />
      </GuestPaymentLayout>
    )
  } else if (viewState === ViewState.Form) {
    return (
      <GuestPaymentLayout>
        <div className="mb-2">
          We need a card on file for your reservation. Please add a payment
          method.
        </div>
        <PaymentForm
          paymentMethods={paymentMethodsQuery.data}
          paymentSession={paymentSessionQuery.data}
          isSavedPayment={true}
          onPaymentCompleted={() => {
            setViewState(ViewState.StatusLoading)
          }}
          onPaymentError={() => {
            setViewState(ViewState.Error)
          }}
        ></PaymentForm>
      </GuestPaymentLayout>
    )
  } else if (viewState === ViewState.StatusLoading) {
    return (
      <VerifyPaymentStatus
        paymentSessionId={paymentSessionId}
        onComplete={(isVerified: boolean) => {
          if (isVerified) {
            setViewState(ViewState.Complete)
          } else {
            setViewState(ViewState.Error)
          }
        }}
      />
    )
  } else if (viewState === ViewState.Complete) {
    return (
      <section className="w-full flex flex-col items-center justify-start gap-8 mt-12">
        <h1 className="header-2 font-semibold">Payment Method Added</h1>
        <img src={CheckinCheckmark} />
      </section>
    )
  } else if (viewState === ViewState.Error) {
    return (
      <div className="flex flex-col items-center justify-start gap-8 mt-12">
        <h1 className="header-2 font-semibold">Payment Method Failed</h1>
        <img src={CheckinError} />
        <a
          className="link link-primary"
          onClick={() => {
            setViewState(ViewState.FormLoading)

            // create a new payment session
            paymentSessionQuery.refetch()
          }}
        >
          Please try again
        </a>
      </div>
    )
  } else {
    return <></>
  }
}

export default GuestPaymentPage
