import React from 'react'
import {useMemberContext} from './MemberPage'

const MemberDashboardPage = () => {
  const {user} = useMemberContext()

  return (
    <div className="p-8 flex flex-col gap-4">
      <h1 className="header-2">Dashboard</h1>
      <p>This is where the member dashboard goes...</p>

      <pre className="p-8 my-4 bg-gray-100 border rounded">
        Member User:
        <br />
        <code>{JSON.stringify(user, null, 2)}</code>
      </pre>
    </div>
  )
}

export default MemberDashboardPage
