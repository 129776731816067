import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import BrandLogo from '../../assets/placemakr-horizontal-logo-dusk.png'
import {
  faBars,
  faDashboard,
  faHandHoldingDollar,
  faHotel,
  faHouse,
  faIdBadge,
  faMoneyBillWave
} from '@fortawesome/free-solid-svg-icons'
import {Link, NavLink} from 'react-router-dom'

const MemberSideBar = () => {
  return (
    <div className="drawer-side">
      <label
        htmlFor="member-sidebar-drawer"
        aria-label="close sidebar"
        className="drawer-overlay"
      ></label>
      <div className="menu w-80 min-h-full bg-base-200 text-base-content py-0 px-2 border-r">
        <div className="flex justify-start items-center gap-1 h-16 mb-8 w-full">
          <div>
            <label
              htmlFor="member-sidebar-drawer"
              className="btn btn-ghost btn-square drawer-button lg:hidden"
            >
              <FontAwesomeIcon icon={faBars} className="text-xl text-primary" />
            </label>
          </div>
          <div>
            <a className="btn btn-ghost text-xl">
              <img src={BrandLogo} className="w-32" />
            </a>
          </div>
        </div>
        <ul className="flex flex-col gap-1 ml-1">
          <li>
            <Link to="/member">
              <FontAwesomeIcon icon={faHouse} className="w-4" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/member/reservations">
              <FontAwesomeIcon icon={faHotel} className="w-4" />
              <span>Reservations</span>
            </Link>
          </li>
          <li>
            <Link to="/member/rates">
              <FontAwesomeIcon icon={faMoneyBillWave} className="w-4" />
              <span>Member Rates</span>
            </Link>
          </li>
          <hr className="m-4" />
          <li>
            <Link to="/member/profile">
              <FontAwesomeIcon icon={faIdBadge} className="w-4" />
              <span>My Profile</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MemberSideBar
