import React from 'react'
import BrandLogo from '../../assets/placemakr-horizontal-logo-dusk.png'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faArrowRightFromBracket,
  faCaretDown,
  faUser,
  faIdBadge,
  faHamburger,
  faBars
} from '@fortawesome/free-solid-svg-icons'

const MemberNavBar = () => {
  return (
    <nav className="navbar bg-white border-b">
      <div className="flex-1">
        <div className="flex items-center gap-1 lg:hidden">
          <label
            htmlFor="member-sidebar-drawer"
            className="btn btn-ghost btn-square drawer-button"
          >
            <FontAwesomeIcon icon={faBars} className="text-xl text-primary" />
          </label>
          <a className="btn btn-ghost text-xl">
            <img src={BrandLogo} className="w-32" />
          </a>
        </div>
      </div>
      <div className="flex-none">
        <div className="flex items-stretch">
          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-square mr-4 w-12 flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faUser} className="text-xl text-primary" />
              <FontAwesomeIcon icon={faCaretDown} style={{fontSize: 16}} />
            </div>
            <ul
              tabIndex={0}
              className="menu dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-72 mt-3"
            >
              <li>
                <Link to="/member/profile">
                  <FontAwesomeIcon icon={faIdBadge} className="w-4" />
                  <span>profile</span>
                </Link>
              </li>
              <hr />
              <li>
                <Link to="/logout">
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className="w-4"
                  />
                  <span>log out</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default MemberNavBar
