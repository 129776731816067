import React from 'react'
import CheckinCheckmark from '../../assets/checkin-checkmark.png'

export interface VerifyIdentityCompleteProps {
  onNext: () => void
}

const VerifyIdentityComplete = ({onNext}: VerifyIdentityCompleteProps) => {
  return (
    <section className="w-1/2 flex flex-col items-center justify-start gap-8 pt-12 mx-auto text-center">
      <h1 className="header-2 font-semibold">Guest Verification Complete</h1>
      <img src={CheckinCheckmark} className="h-52" />
      <button
        className="btn btn-secondary btn-wide font-bold text-xl"
        onClick={() => {
          onNext()
        }}
      >
        continue
      </button>
    </section>
  )
}

export default VerifyIdentityComplete
