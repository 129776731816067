import React, {useEffect, useState} from 'react'
import GuestLoginForm from '../auth/GuestLoginForm'
import GuestLoginCodeForm from '../auth/GuestLoginCodeForm'
import {getUser, setUserToken} from '../../services/token'
import {selectIsAuthenticated} from '../../store/userSlice'
import {setUser} from '../../store/userSlice'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import BrandLogo from '../../assets/placemakr-logo-dusk.png'
import {useLocation, useNavigate} from 'react-router-dom'
import {getLogger} from '../../services/logging'
import {Metric, pushMetric} from '../../services/metrics'
import MemberLogin from '../auth/MemberLoginForm'

const logger = getLogger('LoginPage')

enum LoginMode {
  Member,
  GuestReservationLookup,
  GuestConfirmationCode
}

interface LoginState {
  mode: LoginMode
  hasErrors: boolean
  confirmationCode?: string
}

interface LoginLayoutProps {
  header: string
  loginState: LoginState
  children: any
}

function LoginLayout({header, loginState, children}: LoginLayoutProps) {
  return (
    <section className="max-w-screen-lg min-h-screen mx-auto bg-white flex items-start justify-center pt-12">
      <div className="text-center max-w-lg">
        <div className="flex flex-row justify-center mb-4">
          <div className="flex flex-row justify-center items-center bg-secondary p-4 w-16 h-16 rounded-full">
            <img src={BrandLogo} />
          </div>
        </div>

        {!loginState.hasErrors ? (
          <>
            <h1 className="header-2">{header}</h1>
          </>
        ) : (
          <>
            <h1 className="header-2">
              Oops!
              <br />
              Looks like your log in information is incorrect.
            </h1>
            <div className="text-md mt-2">
              Please try logging in again. If you continue to have issues,
              please
              <a
                className="underline ml-1 cursor-pointer"
                onClick={() => alert('TODO: implement contact us!')}
              >
                contact us
              </a>
              .
            </div>
          </>
        )}

        <div className="my-8">{children}</div>
      </div>
    </section>
  )
}

const LoginPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const [loginState, setLoginState] = useState<LoginState>({
    mode: LoginMode.Member,
    confirmationCode: null,
    hasErrors: false
  })

  const path = location.state?.path || '/'

  useEffect(() => {
    if (isAuthenticated) {
      logger.info({
        message: `User is already authenticated, redirecting to ${path}`
      })
      navigate(path)
    }

    // default to guest login if accessing a guest route
    if (path.startsWith('/guest')) {
      setLoginState({
        ...loginState,
        mode: LoginMode.GuestReservationLookup
      })
    }
  }, [isAuthenticated, path])

  switch (loginState.mode) {
    case LoginMode.Member:
      return (
        <LoginLayout header="Member Login" loginState={loginState}>
          <MemberLogin
            onLoginSuccess={(token: string) => {
              setUserToken(token)
              const user = getUser(token)
              logger.info({
                message: `Login successful for member.  Redirecting to ${path}...`,
                data: {user, token, path}
              })
              pushMetric(Metric.AuthenticationCompleted, 1)
              dispatch(setUser(user))
              navigate(path)
            }}
            onLoginFailure={() => {
              logger.warn({
                message: `Login unsuccessful for member login`
              })
              pushMetric(Metric.AuthenticationFailed, 1)
              setLoginState({
                ...loginState,
                hasErrors: true
              })
            }}
          />
          <div
            className="text-center cursor-pointer mt-4 text-md text-midnight underline"
            onClick={() => {
              setLoginState({
                ...loginState,
                mode: LoginMode.GuestReservationLookup
              })
            }}
          >
            I don't have an account.
            <br />
            Log in as a guest.
          </div>
        </LoginLayout>
      )
    case LoginMode.GuestReservationLookup:
      return (
        <LoginLayout header="Guest Login" loginState={loginState}>
          <GuestLoginForm
            onLoginSuccess={(token: string) => {
              setUserToken(token)
              const user = getUser(token)
              logger.info({
                message: `Login successful for guest using reservation lookup.  Redirecting to ${path}...`,
                data: {token, user, path}
              })
              pushMetric(Metric.AuthenticationCompleted, 1)
              dispatch(setUser(user))
              navigate(path)
            }}
            onLoginFailure={() => {
              logger.warn({
                message: `Login unsuccessful using reservation lookup`
              })
              setLoginState({
                ...loginState,
                hasErrors: true
              })
            }}
          />

          <div
            className="text-center cursor-pointer mt-4 text-md text-midnight underline"
            onClick={() => {
              setLoginState({
                ...loginState,
                mode: LoginMode.Member
              })
            }}
          >
            I have an account. Log in as a member.
          </div>
          <div
            className="text-center cursor-pointer mt-4 text-md text-midnight underline"
            onClick={() => {
              setLoginState({
                ...loginState,
                mode: LoginMode.GuestConfirmationCode
              })
            }}
          >
            I have a confirmation code. Log in as a guest.
          </div>
        </LoginLayout>
      )
    case LoginMode.GuestConfirmationCode:
      return (
        <LoginLayout header="Guest Login" loginState={loginState}>
          <GuestLoginCodeForm
            confirmationCode={loginState.confirmationCode}
            onLoginSuccess={(token: string) => {
              setUserToken(token)
              const user = getUser(token)
              logger.info({
                message: `Login successful for guest using confirmation code.  Redirecting to ${path}...`,
                data: {user, token, path}
              })
              pushMetric(Metric.AuthenticationCompleted, 1)
              dispatch(setUser(user))
              navigate(path)
            }}
            onLoginFailure={() => {
              logger.warn({
                message: `Login unsuccessful using confirmation code`
              })
              pushMetric(Metric.AuthenticationFailed, 1)
              setLoginState({
                ...loginState,
                hasErrors: true
              })
            }}
          />
          <div
            className="text-center cursor-pointer mt-4 text-md text-midnight underline"
            onClick={() => {
              setLoginState({
                ...loginState,
                mode: LoginMode.Member
              })
            }}
          >
            I have an account. Log in as a member.
          </div>
          <div
            className="text-center cursor-pointer mt-4 text-md text-midnight underline"
            onClick={() => {
              setLoginState({
                ...loginState,
                mode: LoginMode.GuestReservationLookup
              })
            }}
          >
            I don't have a confirmation code. Log in as a guest.
          </div>
        </LoginLayout>
      )
    default:
      return <></>
  }
}

export default LoginPage
