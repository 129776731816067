import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'

export interface AppState {
  initialized: boolean
}

// slice
export const appSlice = createSlice({
  name: 'app',
  initialState: {
    initialized: false
  },
  reducers: {
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state = {
        initialized: action.payload
      }
      return state
    }
  }
})

// selectors
export const selectIsInitialized = (state: RootState) => {
  return state.app?.initialized === true
}

// actions
export const {setInitialized} = appSlice.actions

// reducer
export default appSlice.reducer
