import React from 'react'
import CheckinPerson from '../../assets/checkin-person.png'

interface VerifyIdentityWelcomeProps {
  onNext: () => void
}

const VerifyIdentityWelcome = ({onNext}: VerifyIdentityWelcomeProps) => {
  return (
    <section className="min-w-full max-w-lg flex flex-col items-center gap-8 text-center pt-12">
      <h1 className="header-2 font-semibold">Guest Verification</h1>

      <img src={CheckinPerson} />

      <p className="w-1/2">
        At Placemakr, we are dedicated to the safety of our guests, residents,
        and staff. Like any hotel, we need you to verify your identity to secure
        your reservation.
      </p>
      <button
        className="btn btn-secondary btn-wide font-bold text-xl"
        onClick={() => onNext()}
      >
        continue
      </button>
    </section>
  )
}

export default VerifyIdentityWelcome
