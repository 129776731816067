import React from 'react'
import {useMemberContext} from './MemberPage'
import {Link} from 'react-router-dom'

const MemberProfilePage = () => {
  const {user} = useMemberContext()

  return (
    <div className="p-8 flex flex-col gap-4">
      {/* Breadcrumb */}
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/member" className="link link-primary">
              Member
            </Link>
          </li>
          <li>Profile</li>
        </ul>
      </div>
      <h1 className="header-2">Profile</h1>
      <p>This is where the member profile goes...</p>
      <pre className="p-8 my-4 bg-gray-100 border rounded">
        <code>{JSON.stringify(user, null, 2)}</code>
      </pre>
    </div>
  )
}

export default MemberProfilePage
