import React, {useState} from 'react'
import clsx from 'clsx'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import {GuestCredentialsType} from '../../types'
import {useLoginMutation} from '../../services/guestCheckinApi'
import {getLogger} from '../../services/logging'

interface GuestLoginCodeFormProps {
  confirmationCode?: string
  onLoginSuccess: (token: string) => void
  onLoginFailure: () => void
}

interface GuestLoginCodeFormState {
  confirmationCode: string
  lastName: string
  isAuthenticating?: boolean
}

const logger = getLogger('GuestLoginCodeForm')

export default function GuestLoginCodeForm(props: GuestLoginCodeFormProps) {
  const {confirmationCode, onLoginSuccess, onLoginFailure} = props
  const [loginState, setLoginState] = useState<GuestLoginCodeFormState>({
    confirmationCode: confirmationCode || '',
    lastName: '',
    isAuthenticating: false
  })
  const [login] = useLoginMutation()
  const isValid =
    loginState.lastName?.length && loginState.confirmationCode?.length

  return (
    <div>
      <form className="flex flex-col gap-4 mt-8">
        <input
          type="text"
          placeholder="Confirmation code (ex. ABCDEFG-1)"
          className="w-full checkin-input"
          value={loginState.confirmationCode}
          onChange={e => {
            const confirmationCode = e.currentTarget.value || ''
            setLoginState({
              ...loginState,
              confirmationCode: confirmationCode.trim().toUpperCase()
            })
          }}
        />

        <input
          type="text"
          placeholder="Last name"
          className="w-full checkin-input"
          value={loginState.lastName}
          onChange={e => {
            const lastName = e.currentTarget.value.trim()
            setLoginState({...loginState, lastName})
          }}
        />
      </form>

      <button
        className="btn btn-secondary flex flex-row justify-center items-center w-full gap-2 mt-6"
        disabled={!isValid || loginState.isAuthenticating}
        onClick={() => {
          setLoginState({
            ...loginState,
            isAuthenticating: true
          })

          const {confirmationCode, lastName} = loginState
          const credentials = {
            type: GuestCredentialsType.ConfirmationCode,
            confirmationCode,
            lastName
          }

          login(credentials)
            .unwrap()
            .then(({token}) => {
              onLoginSuccess(token)
            })
            .catch(e => {
              logger.warn({
                message: 'Login failure with confirmation code',
                data: {credentials, error: e}
              })

              setLoginState({
                ...loginState,
                isAuthenticating: false
              })

              onLoginFailure()
            })
        }}
      >
        <div className={clsx({hidden: !loginState.isAuthenticating})}>
          <LoadingSpinner size={LoadingSpinnerSize.Small} />
        </div>
        <div className="text-center">
          {' '}
          <FontAwesomeIcon icon={faRightToBracket} className="mr-3" />
          {loginState.isAuthenticating ? 'logging in...' : 'login'}
        </div>
      </button>
    </div>
  )
}
