import Cookies from 'js-cookie'
import {AuthenticatedUser, GuestUser, MemberUser, UserRole} from '../types'

export interface TokenPayload {
  iss: string
  sub: string
  exp: number
  iat: number
}

export interface GuestTokenPayload extends TokenPayload {
  first_name: string
  last_name: string
  property_id: string
  booking_id: string
  reservation_id: string
  role: string
}

export interface CognitoTokenPayload extends TokenPayload {
  email: string
  given_name: string
  family_name: string
}

export const USER_TOKEN_COOKIE = 'user-token'

// TODO: externalize configs
const COGNITO_TOKEN_ISSUER =
  'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_sSxTyOBek'
const GUEST_TOKEN_ISSUER = 'https://www.placemakr.com/guest-checkin'

export function getUserToken(): string {
  return Cookies.get(USER_TOKEN_COOKIE)
}

export function setUserToken(token: string): void {
  Cookies.set(USER_TOKEN_COOKIE, token)
}

export function removeUserToken(): void {
  Cookies.remove(USER_TOKEN_COOKIE)
}

export function parseJwt<T extends TokenPayload>(token: string): T {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(c => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export function getUser(token: string): AuthenticatedUser {
  const tokenPayload = parseJwt(token)
  if (tokenPayload.iss === COGNITO_TOKEN_ISSUER) {
    const {email, given_name, family_name} = tokenPayload as CognitoTokenPayload
    return {
      role: UserRole.Member,
      isAuthenticated: true,
      email,
      firstName: given_name,
      lastName: family_name
    } as MemberUser
  } else if (tokenPayload.iss === GUEST_TOKEN_ISSUER) {
    const {
      sub,
      first_name,
      last_name,
      reservation_id,
      booking_id
    } = tokenPayload as GuestTokenPayload
    return {
      role: UserRole.Guest,
      isAuthenticated: true,
      email: sub,
      firstName: first_name,
      lastName: last_name,
      reservationId: reservation_id,
      bookingId: booking_id
    } as GuestUser
  } else {
    throw new Error('Invalid token issuer')
  }
}
