import React from 'react'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'

interface LoadingScreenProps {
  message?: string
}

const LoadingScreen = ({message}: LoadingScreenProps) => {
  const loadingMessage = message || 'Loading...'
  return (
    <div className="min-h-screen flex justify-center items-center gap-2">
      <LoadingSpinner size={LoadingSpinnerSize.Large} />
      <div>{loadingMessage}</div>
    </div>
  )
}

export default LoadingScreen
