import React from 'react'
import {format, parseISO} from 'date-fns'
import CheckinIcon from '../../assets/checkin-icon.svg'
import CheckoutIcon from '../../assets/checkout-icon.svg'

export interface GuestCheckinInfoProps {
  checkinDate: string
  checkinTime: string
  checkoutDate: string
  checkoutTime: string
}

function formatDate(s: string): string {
  if (!s) {
    return '-'
  }

  try {
    return format(parseISO(s), 'MMM d, yyyy')
  } catch (e) {
    console.warn(`Invalid date: ${s}`)
    return '-'
  }
}

export default function GuestCheckinInfo({
  checkinDate,
  checkinTime,
  checkoutDate,
  checkoutTime
}: GuestCheckinInfoProps) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col items-left">
        <span className="uppercase font-bold text-xs text-cabana tracking-widest">
          Check-in:
        </span>
        <div className="flex flex-row items-center gap-4">
          <div className="checkin bg-cabana rounded-md p-2 w-10">
            <img src={CheckinIcon} />
          </div>
          <div className="flex flex-col">
            {checkinDate ? (
              <span className="text-md">{formatDate(checkinDate)}</span>
            ) : (
              ''
            )}
            <span className="text-md font-bold">{checkinTime}</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-left">
        <span className="uppercase font-bold text-xs text-cabana tracking-widest">
          Check-out:
        </span>
        <div className="flex flex-row items-center gap-4">
          <div className="bg-cabana rounded-md p-2 w-10">
            <img src={CheckoutIcon} />
          </div>
          <div className="flex flex-col">
            {checkoutDate ? (
              <span className="text-md">{formatDate(checkoutDate)}</span>
            ) : (
              ''
            )}
            <span className="text-md font-bold">{checkoutTime}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
