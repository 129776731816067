import React, {useEffect, useState} from 'react'
import {Outlet, useOutletContext} from 'react-router-dom'
import {useAppSelector} from '../../store/hooks'
import {selectMemberUser} from '../../store/userSlice'
import {MemberUser} from '../../types'
import LoadingScreen from '../common/LoadingScreen'
import MemberNavBar from '../nav/MemberNavBar'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import BrandLogo from '../../assets/placemakr-horizontal-logo-dusk.png'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import MemberSideBar from '../nav/MemberSideBar'

interface MemberContext {
  user: MemberUser
}

const MemberPage = () => {
  const [memberContext, setMemberContext] = useState<MemberContext>({
    user: null
  })
  const user = useAppSelector(selectMemberUser)

  useEffect(() => {
    setMemberContext({
      user
    })
  }, [user])

  return (
    <section className="drawer lg:drawer-open">
      <input
        id="member-sidebar-drawer"
        type="checkbox"
        className="drawer-toggle"
      />
      <div className="drawer-content bg-white">
        <MemberNavBar />

        {/* Member Portal Content */}
        <div className="max-w-screen-lg min-h-screen mx-auto">
          {false ? <LoadingScreen message={`Loading member info...`} /> : ''}

          {true ? <Outlet context={memberContext} /> : ''}
        </div>
      </div>
      <MemberSideBar />
    </section>
  )
}

export default MemberPage

export function useMemberContext() {
  return useOutletContext<MemberContext>()
}
