import React, {useEffect, useState} from 'react'
import {Outlet, useNavigate, useOutletContext} from 'react-router-dom'
import {useAppSelector} from '../../store/hooks'
import {selectGuestUser} from '../../store/userSlice'
import {
  useGetCheckinInfoQuery,
  useGetReservationQuery
} from '../../services/guestCheckinApi'
import {CheckinInfo, GuestUser, Reservation} from '../../types'
import LoadingScreen from '../common/LoadingScreen'
import GuestNavBar from '../nav/GuestNavBar'

interface GuestContext {
  user: GuestUser
  reservation: Reservation
  checkinInfo: CheckinInfo
}

const GuestPage = () => {
  const navigate = useNavigate()
  const [guestContext, setGuestContext] = useState<GuestContext>({
    user: null,
    reservation: null,
    checkinInfo: null
  })
  const user = useAppSelector(selectGuestUser)
  const reservationQuery = useGetReservationQuery({
    propertyId: user.propertyId,
    reservationId: user.reservationId
  })
  const checkinInfoQuery = useGetCheckinInfoQuery(null)

  useEffect(() => {
    setGuestContext({
      user,
      reservation: reservationQuery.data,
      checkinInfo: checkinInfoQuery.data
    })
  }, [user, reservationQuery.data, checkinInfoQuery.data])

  return (
    <section>
      {/* Navbar */}
      <GuestNavBar />

      {/* Guest Portal Content */}
      <div className="max-w-screen-lg min-h-screen mx-auto border-x bg-white">
        {!reservationQuery.data ? (
          <LoadingScreen message={`Loading guest reservation...`} />
        ) : (
          ''
        )}

        {!reservationQuery.isLoading && reservationQuery.data ? (
          <Outlet context={guestContext} />
        ) : (
          ''
        )}
      </div>
    </section>
  )
}

export default GuestPage

export function useGuestContext() {
  return useOutletContext<GuestContext>()
}
