import React from 'react'
import {useNavigate} from 'react-router-dom'
import BrandLogo from '../../assets/placemakr-logo-sky.png'
import {getLogger} from '../../services/logging'
import {Metric, pushMetric} from '../../services/metrics'
import {getPropertyId} from '../../services/propertyContext'

const logger = getLogger('CheckinWelcomePage')

const CheckinWelcomePage = () => {
  const navigate = useNavigate()
  const propertyId = getPropertyId()
  return (
    <section
      className={
        'min-h-screen flex flex-col justify-center items-center ' +
        'bg-primary text-white text-center'
      }
    >
      <img src={BrandLogo} className="w-16 mb-8" />
      <h1 className="tracking-wide" style={{fontSize: '60px'}}>
        Welcome to <span className="font-bold">Placemakr</span>
      </h1>
      <h2 className="mb-8" style={{fontSize: '40px'}}>
        Let's get you checked in.
      </h2>
      <button
        className="btn btn-secondary btn-lg btn-wide font-black text-2xl"
        onClick={() => {
          logger.debug({message: 'Starting guest checkin process...'})
          pushMetric(Metric.CheckinStarted, 1, {Property: propertyId})
          navigate('/checkin')
        }}
      >
        Start
      </button>
    </section>
  )
}

export default CheckinWelcomePage
