import Cookies from 'js-cookie'

export const PROPERTY_ID_COOKIE = 'property-id'

export function getPropertyId(): string {
  return Cookies.get(PROPERTY_ID_COOKIE)
}

export function setPropertyId(propertyId: string): void {
  Cookies.set(PROPERTY_ID_COOKIE, propertyId, {expires: 365})
}

export function removePropertyId(): void {
  Cookies.remove(PROPERTY_ID_COOKIE)
}
