import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getConfig} from './config'
import {getUserToken} from './token'
import {getLogger} from './logging'
import {
  GuestCredentials,
  ReservationCheckinParams,
  ReservationParams
} from '../types'

const {guestCheckinApiUrl} = getConfig()
const logger = getLogger('guestCheckinApi')

export const guestCheckinApi = createApi({
  reducerPath: 'guestCheckinApi',
  baseQuery: fetchBaseQuery({
    baseUrl: guestCheckinApiUrl,
    prepareHeaders: async (headers): Promise<Headers> => {
      try {
        // injects authorization token into request headers
        const token = getUserToken()
        if (token?.length) {
          headers.set('Authorization', `Bearer ${token}`)
        }
      } catch (e) {
        logger.error({
          message: 'Error occurred setting guest token on HTTP request',
          errorData: {
            error: e
          }
        })
      }
      return headers
    }
  }),
  tagTypes: [],
  endpoints: builder => ({
    cancelReservation: builder.mutation({
      query(reservationId: string) {
        return {
          url: `/cancel-reservation?reservationId=${reservationId}`,
          method: 'PUT'
        }
      },
      invalidatesTags: []
    }),
    checkinGuest: builder.mutation({
      query() {
        return {
          url: '/checkin',
          method: 'PUT'
        }
      },
      invalidatesTags: []
    }),
    getCheckinInfo: builder.query({
      query() {
        return `/checkin-info`
      },
      providesTags: []
    }),
    getPaymentMethods: builder.query({
      query() {
        return `/payment-methods`
      },
      providesTags: []
    }),
    getPaymentSession: builder.query({
      query() {
        return {
          url: `/payment-session`,
          method: 'POST',
          body: {
            'returnUrl': 'http://www.example.com',
            'countryCode': 'US'
          }
        }
      },
      providesTags: []
    }),
    getPaymentStatus: builder.query({
      query(paymentSessionId: string) {
        return `/payment-status?paymentSessionId=${paymentSessionId}`
      },
      providesTags: []
    }),
    getProperty: builder.query({
      query(propertyId: string) {
        return `/property?propertyId=${propertyId}`
      },
      providesTags: []
    }),
    getReservation: builder.query({
      query({propertyId, reservationId}: ReservationParams) {
        return `/reservation?propertyId=${propertyId}&reservationId=${reservationId}`
      },
      providesTags: []
    }),
    getReservationCheckin: builder.query({
      query({
        checkinPropertyId,
        propertyId,
        reservationId
      }: ReservationCheckinParams) {
        return (
          `/reservation-checkin` +
          `?checkinPropertyId=${checkinPropertyId}&propertyId=${propertyId}&reservationId=${reservationId}`
        )
      },
      providesTags: []
    }),
    getVerificationStatus: builder.query({
      query() {
        return `/verification-status`
      },
      providesTags: []
    }),
    login: builder.mutation({
      query(credentials: GuestCredentials) {
        return {
          url: '/authenticate',
          method: 'POST',
          body: credentials
        }
      },
      invalidatesTags: []
    }),
    validateToken: builder.mutation({
      query() {
        return {
          url: '/introspect',
          method: 'GET'
        }
      },
      invalidatesTags: []
    })
  })
})

export const {
  useCancelReservationMutation,
  useCheckinGuestMutation,
  useGetCheckinInfoQuery,
  useGetPaymentMethodsQuery,
  useGetPaymentSessionQuery,
  useGetPaymentStatusQuery,
  useGetPropertyQuery,
  useGetReservationQuery,
  useGetReservationCheckinQuery,
  useGetVerificationStatusQuery,
  useLoginMutation,
  useValidateTokenMutation
} = guestCheckinApi
