import React, {useEffect, useState} from 'react'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {useGetPaymentStatusQuery} from '../../services/guestCheckinApi'

interface VerifyPaymentStatusProps {
  paymentSessionId: string
  onComplete: (isVerified: boolean) => void
}

const POLL_DELAY = 5000
const POLL_ATTEMPTS = 10

const VerifyPaymentStatus = ({
  paymentSessionId,
  onComplete
}: VerifyPaymentStatusProps) => {
  const [pollAttempts, setPollAttempts] = useState<number>(POLL_ATTEMPTS)
  const paymentStatusQuery = useGetPaymentStatusQuery(paymentSessionId)

  console.log('paymentSessionId', paymentSessionId)
  console.log('paymentStatusQuery', paymentStatusQuery)

  useEffect(() => {
    const {isLoading, refetch} = paymentStatusQuery
    const paymentStatus = paymentStatusQuery.data?.status

    if (pollAttempts > 0 && !isLoading && !paymentStatus) {
      setTimeout(() => {
        console.log('Polling for payment status...')
        refetch()
        setPollAttempts(pollAttempts - 1)
      }, POLL_DELAY)
    }

    if (pollAttempts <= 0 || paymentStatus) {
      console.log('Polling complete.')
      const isVerified = paymentStatusQuery.data?.status === 'success'
      console.log('Payment is verified?', isVerified)
      onComplete(isVerified)
    }
  }, [pollAttempts, paymentStatusQuery.isLoading])

  return (
    <div className="flex justify-center items-center gap-2">
      <LoadingSpinner size={LoadingSpinnerSize.Large} />
      <div>Verifying Payment Method...</div>
    </div>
  )
}

export default VerifyPaymentStatus
