import React, {forwardRef} from 'react'
import DatePicker from 'react-datepicker'
import clsx from 'clsx'

export interface DatePickerInputProps {
  onChange: (d: Date) => void
  value?: Date
  placeholder?: string
}

export default function DatePickerInput(props: DatePickerInputProps) {
  const DatePickerTextInput = forwardRef<HTMLDivElement>(
    ({value, onClick}: any, ref) => {
      const hasValue = !!value
      const inputText = hasValue ? value : props.placeholder || ''
      return (
        <div
          ref={ref}
          tabIndex={0}
          className={clsx(
            'w-full checkin-input flex flex-row justify-start items-center',
            {
              'text-gray-400': !hasValue
            }
          )}
          onClick={onClick}
        >
          <div>{inputText}</div>
        </div>
      )
    }
  )

  return (
    <DatePicker
      selected={props.value}
      onChange={props.onChange}
      className="w-full checkin-input"
      customInput={<DatePickerTextInput />}
      disabledKeyboardNavigation
    />
  )
}
