import React from 'react'
import CheckinError from '../../assets/checkin-error.png'

const VerifyIdentityRejected = () => {
  return (
    <section className="w-1/2 flex flex-col items-center justify-start gap-8 pt-12 mx-auto text-center">
      <h1 className="header-2 font-semibold">Guest Verification Failed</h1>
      <img src={CheckinError} className="h-52" />
      <p className="text-lg text-center">
        We were unable verify your identity.
        <br />
        Please contact support for help.
      </p>
      <button
        className="btn btn-secondary btn-wide font-bold text-xl"
        onClick={() => alert('help!')}
      >
        get help
      </button>
    </section>
  )
}

export default VerifyIdentityRejected
