import React, {useEffect, useState} from 'react'
import {getLogger} from '../../services/logging'
import {useGuestContext} from './GuestPage'
import {formatDateString} from '../../services/utils'
import {Link} from 'react-router-dom'
import {useCancelReservationMutation} from '../../services/guestCheckinApi'
import {CheckinStatus} from '../../types'
import Alert from '../common/Alert'
import LoadingScreen from '../common/LoadingScreen'

const logger = getLogger('GuestCancelPage')

enum ViewState {
  ConfirmCancel = 'ConfirmCancel',
  OTACancel = 'OTACancel',
  CancelInProgress = 'CancelInProgress',
  CancelSuccess = 'CancelSuccess',
  CancelError = 'CancelError',
  CancelInvalidStatus = 'CancelInvalidStatus'
}

const GuestCancelPage = () => {
  const {reservation} = useGuestContext()
  const [cancelReservation] = useCancelReservationMutation()

  const [view, setView] = useState(ViewState.ConfirmCancel)

  useEffect(() => {
    const isDirect = ['Direct', 'Ibe'].includes(reservation?.channelCode)
    const isCancellable = reservation?.status === CheckinStatus.Confirmed

    if (!isCancellable) {
      setView(ViewState.CancelInvalidStatus)
    } else if (!isDirect) {
      setView(ViewState.OTACancel)
    } else {
      setView(ViewState.ConfirmCancel)
    }
  }, [reservation])

  return (
    <div className="px-8 py-4">
      {/* Breadcrumb */}
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/guest" className="link link-primary">
              Reservation
            </Link>
          </li>
          <li>Cancellation</li>
        </ul>
      </div>
      <h2 className="header-2">Cancel Reservation</h2>

      <div className="my-8">
        {/* Confirm Cancel View */}
        {view === ViewState.ConfirmCancel ? (
          <>
            <h2 className="text-xl font-semibold">Confirm Cancellation</h2>
            <div>
              Are you sure you want to cancel this reservation? Please read the
              cancellation policy carefully.
            </div>

            {reservation ? (
              <div className="mt-4 mb-8">
                <div className="flex flex-col gap-2 mb-4 p-4 border rounded border-red-500 bg-red-100">
                  <div>
                    <div className="uppercase font-bold text-xs tracking-widest">
                      Cancellation Policy:
                    </div>
                    <div>
                      {reservation.cancellationFee?.name} -{' '}
                      {reservation.cancellationFee?.description}
                    </div>
                  </div>
                  <div>
                    <div className="uppercase font-bold text-xs tracking-widest">
                      Cancellation Fee:
                    </div>
                    <div>${reservation.cancellationFee?.fee?.amount}</div>
                  </div>
                </div>
                <div className="flex flex-row gap-2">
                  <div className="font-bold">Reservation:</div>
                  <div>{reservation.id}</div>
                </div>
                <div className="flex flex-col gap-2 my-4">
                  <div>
                    <div className="uppercase font-bold text-xs tracking-widest">
                      Property:
                    </div>
                    <div>{reservation.property?.name}</div>
                  </div>
                  <div>
                    <div className="uppercase font-bold text-xs tracking-widest">
                      Unit Type:
                    </div>
                    <div>{reservation.unitGroup.name}</div>
                  </div>
                </div>
                <div className="flex flex-row my-4">
                  <div>
                    <div className="uppercase font-bold text-xs tracking-widest">
                      Check-In:
                    </div>
                    <div>{formatDateString(reservation?.arrival)}</div>
                  </div>
                  <div className="ml-10">
                    <div className="uppercase font-bold text-xs tracking-widest">
                      Check-Out:
                    </div>
                    <div>{formatDateString(reservation.departure)}</div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="flex flex-row justify-end">
              <Link to="/guest" className="btn btn-ghost mr-4">
                go back
              </Link>
              <button
                className="btn btn-secondary"
                onClick={e => {
                  e.stopPropagation()
                  setView(ViewState.CancelInProgress)

                  const cancelPromise = cancelReservation(
                    reservation?.id
                  ).unwrap()

                  cancelPromise
                    .then(() => {
                      logger.info({
                        message: `Cancelled reservation ${reservation.id}`
                      })
                      setView(ViewState.CancelSuccess)
                      reservation.status = CheckinStatus.Canceled
                    })
                    .catch(error => {
                      logger.error({
                        message: `Error canceling reservation ${reservation.id}`,
                        data: {
                          reservation
                        },
                        errorData: {error}
                      })
                      setView(ViewState.CancelError)
                    })
                }}
              >
                Confirm Cancellation
              </button>
            </div>
          </>
        ) : (
          ''
        )}

        {/* Invalid Status */}
        {view === ViewState.CancelInvalidStatus ? (
          <>
            <Alert type="error">
              <span className="font-bold text-2xl">
                Unable to cancel reservation
              </span>
              <p className="mt-4">
                Reservations can only be canceled prior to checkin. If this is a
                mistake, please contact support.
              </p>
            </Alert>
            <Link to="/guest" className="btn btn-ghost mt-4 mr-4">
              go back
            </Link>
          </>
        ) : (
          ''
        )}

        {/* OTA Cancel View */}
        {view === ViewState.OTACancel ? (
          <>
            <Alert type="error">
              <span className="font-bold text-2xl">
                Unable to cancel reservation
              </span>
              <p className="mt-4">
                It looks like you booked with an online travel agent like
                Expedia, Booking.com, or AirBnb. In order for you to cancel your
                reservation at Placemakr, you must do so directly through them.
              </p>
            </Alert>
            <Link to="/guest" className="btn btn-ghost mt-4 mr-4">
              go back
            </Link>
          </>
        ) : (
          ''
        )}

        {/* Cancelling View */}
        {view === ViewState.CancelInProgress ? (
          <LoadingScreen
            message={`Cancelling reservation ${reservation.id}...`}
          />
        ) : (
          ''
        )}

        {/* Cancel Success View */}
        {view === ViewState.CancelSuccess ? (
          <>
            <Alert type="success">
              <span className="font-bold text-2xl">Success!</span>
              <p className="mt-4">
                Reservation {reservation.id} successfully cancelled.
              </p>
            </Alert>
            <Link to="/guest" className="btn btn-ghost mt-4 mr-4">
              go back
            </Link>
          </>
        ) : (
          ''
        )}

        {/* Cancel Error View */}
        {view === ViewState.CancelError ? (
          <>
            <Alert type="error">
              <span className="font-bold text-2xl">
                Failed to cancel reservation
              </span>
              <p className="mt-4">
                Failed to cancel reservation {reservation.id}. If the problem
                persists, please contact support.
                <br />
                <br />
                <a
                  className="link mt-4"
                  onClick={e => {
                    setView(ViewState.ConfirmCancel)
                  }}
                >
                  Please try again
                </a>
              </p>
            </Alert>
            <Link to="/guest" className="btn btn-ghost mt-4 mr-4">
              go back
            </Link>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default GuestCancelPage
