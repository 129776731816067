import React, {ReactNode, useEffect, useState} from 'react'
import {useGetVerificationStatusQuery} from '../../services/guestCheckinApi'
import {getLogger} from '../../services/logging'
import {Metric, pushMetric} from '../../services/metrics'
import {getPropertyId} from '../../services/propertyContext'
import VerifyIdentityWelcome from '../checkin/VerifyIdentityWelcome'
import VerifyIdentityForm from '../checkin/VerifyIdentityForm'
import VerifyIdentityComplete from '../checkin/VerifyIdentityComplete'
import VerifyIdentityRejected from '../checkin/VerifyIdentityRejected'
import LoadingSpinner, {LoadingSpinnerSize} from '../common/LoadingSpinner'
import {AutohostVerificationStatus} from '../../types'
import {useGuestContext} from './GuestPage'
import {Link} from 'react-router-dom'

enum ViewState {
  Loading = 'Loading',
  Welcome = 'Welcome',
  Form = 'Form',
  Complete = 'Complete',
  Rejected = 'Rejected'
}

const logger = getLogger('GuestVerificationPage')

interface GuestVerificationLayoutProps {
  children: ReactNode
}

function GuestVerificationLayout({children}: GuestVerificationLayoutProps) {
  return (
    <div className="px-8 py-4">
      <div className="text-sm breadcrumbs">
        <ul>
          <li>
            <Link to="/guest" className="link link-primary">
              Reservation
            </Link>
          </li>
          <li>Guest Verification</li>
        </ul>
      </div>
      {children}
    </div>
  )
}

const GuestVerificationPage = () => {
  const [viewState, setViewState] = useState<ViewState>(ViewState.Loading)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const {reservation, checkinInfo} = useGuestContext()
  const verificationStatusQuery = useGetVerificationStatusQuery(null)

  useEffect(() => {
    // determine view status based on verification status
    if (!isInitialized) {
      if (verificationStatusQuery.isSuccess) {
        setIsInitialized(true)
        const verificationStatus =
          verificationStatusQuery.data?.autohostVerificationStatus

        if (verificationStatus === AutohostVerificationStatus.Verified) {
          setViewState(ViewState.Complete)
        } else if (verificationStatus === AutohostVerificationStatus.Review) {
          setViewState(ViewState.Complete)
        } else {
          setViewState(ViewState.Welcome)
        }
      } else if (verificationStatusQuery.isError) {
        logger.error({
          message: 'Initial verification status query failed.',
          data: {verificationStatusQuery}
        })
        setIsInitialized(true)
        setViewState(ViewState.Welcome)
      }
    }
  }, [verificationStatusQuery])

  logger.debug({
    message: 'View state changed',
    data: {
      viewState
    }
  })

  if (viewState === ViewState.Loading) {
    return (
      <div className="min-h-screen flex justify-center items-center gap-2">
        <LoadingSpinner size={LoadingSpinnerSize.Large} />
        <div>Loading...</div>
      </div>
    )
  } else if (viewState === ViewState.Welcome) {
    return (
      <GuestVerificationLayout>
        <VerifyIdentityWelcome onNext={() => setViewState(ViewState.Form)} />
      </GuestVerificationLayout>
    )
  } else if (viewState === ViewState.Form) {
    return (
      <GuestVerificationLayout>
        <VerifyIdentityForm
          verificationUrl={checkinInfo?.autohostVerificationUrl}
          onNext={() => {
            setViewState(ViewState.Complete)
          }}
        />
      </GuestVerificationLayout>
    )
  } else if (viewState === ViewState.Complete) {
    return (
      <GuestVerificationLayout>
        <VerifyIdentityComplete
          onNext={() => {
            pushMetric(Metric.IdentityVerificationCompleted, 1, {
              Property: getPropertyId()
            })
            alert('Complete verification')
          }}
        />
      </GuestVerificationLayout>
    )
  } else if (viewState === ViewState.Rejected) {
    return (
      <GuestVerificationLayout>
        <VerifyIdentityRejected />
      </GuestVerificationLayout>
    )
  } else {
    return <></>
  }
}

export default GuestVerificationPage
