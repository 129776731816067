import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from './store'
import {
  AuthenticatedUser,
  GuestUser,
  MemberUser,
  User,
  UserRole
} from '../types'

// slice
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false
  },
  reducers: {
    setUser<T extends AuthenticatedUser>(state: any, action: PayloadAction<T>) {
      state = action.payload
      return state
    },
    clearUser(state: any, action: PayloadAction<void>) {
      state = {
        isAuthenticated: false
      }
      return state
    }
  }
})

// selectors
export const selectUser = (state: RootState): User => state.user

export const selectAuthenticatedUser = (state: RootState): AuthenticatedUser =>
  state.user

export const selectIsAuthenticated = (state: RootState): boolean => {
  const user = selectUser(state)
  return user?.isAuthenticated === true
}

export const selectUserRole = (state: RootState): UserRole => {
  const user = selectAuthenticatedUser(state)
  return user?.role
}

export const selectIsGuest = (state: RootState): boolean => {
  const role = selectUserRole(state)
  return role === UserRole.Guest
}

export const selectIsMember = (state: RootState): boolean => {
  const role = selectUserRole(state)
  return role === UserRole.Member
}

export const selectGuestUser = (state: RootState): GuestUser => {
  const user = selectAuthenticatedUser(state)
  return user as GuestUser
}

export const selectMemberUser = (state: RootState): MemberUser => {
  const user = selectAuthenticatedUser(state)
  return user as MemberUser
}

// actions
export const {setUser, clearUser} = userSlice.actions

// reducer
export default userSlice.reducer
