import React from 'react'
import {parseISO, isSameDay, isAfter, isBefore} from 'date-fns'
import {AutohostVerificationStatus, CheckinStatus} from '../../types'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCircleExclamation,
  faWarning,
  faEllipsisH,
  faCheckCircle,
  faCalendar,
  faBed,
  faHand,
  faConciergeBell,
  faIdCard
} from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'

export interface GuestCheckinStatusProps {
  checkinStatus: CheckinStatus
  checkinDate: string
  checkoutDate: string
  checkoutTime: string
  verificationStatus: AutohostVerificationStatus
  verificationUrl: string
  hasPin: boolean
}

interface VerificationUrlProps {
  verificationUrl: string
}

interface CheckoutTimeProps {
  checkoutTime: string
}

function GuestNoShow() {
  return (
    <div className="alert alert-error my-8">
      <FontAwesomeIcon icon={faCircleExclamation} className="text-4xl" />
      <div className="px-4">
        <span className="font-bold">
          This reservation has been cancelled due to no show.
        </span>
        <br />
        If you believe you are receiving this in error please contact us or
        re-book. We hope to see you soon!
        <br />
        <button
          className="link"
          onClick={() => (window.location.href = 'https://www.placemakr.com')}
        >
          Book Again
        </button>
      </div>
    </div>
  )
}

function GuestCanceled() {
  return (
    <div className="alert alert-error my-8">
      <FontAwesomeIcon icon={faCircleExclamation} className="text-4xl" />
      <div className="px-4">
        <span className="font-bold">This reservation has been cancelled.</span>
        <br />
        If you believe you are receiving this in error please contact us or
        re-book. We hope to see you soon!
        <br />
        <button
          className="link"
          onClick={() => (window.location.href = 'https://www.placemakr.com')}
        >
          Book Again
        </button>
      </div>
    </div>
  )
}

function GuestPrecheckPending({verificationUrl}: VerificationUrlProps) {
  return (
    <div className="alert alert-info my-8">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="text-4xl text-cabana"
      />
      <div className="px-4">
        <span className="font-bold">Please verify your information</span>&nbsp;
        <p className="my-2">
          Prior to check-in, all guests are required to complete verification.
        </p>
        <Link to="/guest/verification" className="link">
          <FontAwesomeIcon icon={faIdCard} className="mr-1" />
          <span>verify my information</span>
        </Link>
      </div>
    </div>
  )
}

function GuestPrecheckReview() {
  return (
    <div className="alert alert-warning my-8">
      <FontAwesomeIcon icon={faEllipsisH} className="text-4xl" />
      <div className="px-4">
        <span className="font-bold">Verification under review</span>
        <br />
        We'll reach out if we need anything else to complete your reservation.
      </div>
    </div>
  )
}

function GuestPrecheckIncomplete({verificationUrl}: VerificationUrlProps) {
  return (
    <div className="alert alert-warning my-8">
      <FontAwesomeIcon icon={faWarning} className="text-4xl" />
      <div className="px-4">
        <span className="font-bold">Please finish verification</span>
        <br />
        All guests must complete verification prior to check-in.
        <br />
        <button
          onClick={() => window.open(verificationUrl, '_blank')}
          className="link"
        >
          Verify My Information
        </button>
      </div>
    </div>
  )
}

function GuestPrecheckVerified() {
  return (
    <div className="alert alert-success my-8">
      <FontAwesomeIcon icon={faCheckCircle} className="text-4xl" />
      <div className="px-4">
        <span className="font-bold">Verification approved</span>&nbsp;
        <br />
        The excitement is real! We'll see you soon.
      </div>
    </div>
  )
}

function GuestCheckinReadyPin() {
  return (
    <div className="alert alert-info my-8">
      <FontAwesomeIcon icon={faCalendar} className="text-4xl text-cabana" />
      <div className="px-4">
        <span className="font-bold">Today is check-in day!</span>
        <br />
        We'll text you your room number and PIN code when they're ready.
      </div>
    </div>
  )
}

function GuestCheckinReadyNoPin() {
  return (
    <div className="alert alert-info my-8">
      <FontAwesomeIcon icon={faCalendar} className="text-4xl text-cabana" />
      <div className="px-4">
        <span className="font-bold">Today is check-in day!</span>
        <br />
        Stop by the front desk when you arrive to receive your room key.
      </div>
    </div>
  )
}

function GuestCheckinMidStay() {
  return (
    <div className="alert alert-info my-8">
      <FontAwesomeIcon icon={faBed} className="text-4xl text-cabana" />
      <div className="px-4">
        <span className="font-bold">
          We hope you're having a wonderful stay!
        </span>
        <br />
        Please don't hesitate to contact us if there is anything we can do to
        make your experience better.
      </div>
    </div>
  )
}

function GuestCheckout({checkoutTime}: CheckoutTimeProps) {
  return (
    <div className="alert alert-info my-8">
      <FontAwesomeIcon icon={faHand} className="text-4xl text-cabana" />
      <div className="px-4">
        <span className="font-bold">Check-out is {checkoutTime}.</span>
        <br />
        We hope that you had a fantastic stay! Please don't hesitate to reach
        out if you need anything before heading out.
      </div>
    </div>
  )
}

function GuestPostCheckout() {
  return (
    <div className="alert alert-info my-8">
      <FontAwesomeIcon
        icon={faConciergeBell}
        className="text-4xl text-cabana"
      />
      <div className="px-4">
        <span className="font-bold">Thanks for staying with us.</span>
        <br />
        We hope that you had a fantastic stay! Please don't hesitate to reach
        out if you need anything. We look forward to seeing you again.
      </div>
    </div>
  )
}

function isPrecheck(date: Date, checkinDate: Date) {
  return isBefore(date, checkinDate)
}

function isVerificationPeriod(date: Date, checkinDate: Date) {
  return isBefore(date, checkinDate) || isSameDay(date, checkinDate)
}

function isCheckinDay(date: Date, checkinDate: Date) {
  return isSameDay(date, checkinDate)
}

function isMidStay(date: Date, checkinDate: Date, checkoutDate: Date) {
  return isAfter(date, checkinDate) && isBefore(date, checkoutDate)
}

function isCheckoutDay(date: Date, checkoutDate: Date) {
  return isSameDay(date, checkoutDate)
}

function isPostStay(date: Date, checkoutDate: Date) {
  return isAfter(date, checkoutDate)
}

export default function GuestCheckinStatus({
  checkinStatus,
  checkinDate,
  checkoutDate,
  checkoutTime,
  verificationStatus,
  verificationUrl,
  hasPin
}: GuestCheckinStatusProps) {
  const checkin = parseISO(checkinDate)
  const checkout = parseISO(checkoutDate)
  const today = new Date()

  if (checkinStatus === CheckinStatus.NoShow) {
    return <GuestNoShow />
  } else if (checkinStatus === CheckinStatus.Canceled) {
    return <GuestCanceled />
  } else if (checkinStatus === CheckinStatus.InHouse) {
    return <GuestCheckinMidStay />
  } else if (checkinStatus === CheckinStatus.CheckedOut) {
    return <GuestPostCheckout />
  } else if (
    isVerificationPeriod(today, checkin) &&
    verificationStatus === AutohostVerificationStatus.Review
  ) {
    return <GuestPrecheckReview />
  } else if (
    isVerificationPeriod(today, checkin) &&
    verificationStatus === AutohostVerificationStatus.Incomplete
  ) {
    return <GuestPrecheckIncomplete verificationUrl={verificationUrl} />
  } else if (
    isVerificationPeriod(today, checkin) &&
    verificationStatus === AutohostVerificationStatus.Pending
  ) {
    return <GuestPrecheckPending verificationUrl={verificationUrl} />
  } else if (isVerificationPeriod(today, checkin) && !verificationStatus) {
    return <GuestPrecheckPending verificationUrl={verificationUrl} />
  } else if (
    isPrecheck(today, checkin) &&
    verificationStatus === AutohostVerificationStatus.Verified
  ) {
    return <GuestPrecheckVerified />
  } else if (
    isCheckinDay(today, checkin) &&
    verificationStatus === AutohostVerificationStatus.Verified &&
    hasPin
  ) {
    return <GuestCheckinReadyPin />
  } else if (isCheckinDay(today, checkin)) {
    return <GuestCheckinReadyNoPin />
  } else if (isCheckoutDay(today, checkout)) {
    return <GuestCheckout checkoutTime={checkoutTime} />
  } else if (isMidStay(today, checkin, checkout)) {
    return <GuestCheckinMidStay />
  } else if (isPostStay(today, checkout)) {
    return <GuestPostCheckout />
  } else {
    return <></>
  }
}
