import React from 'react'
import {format, parseISO} from 'date-fns'
import {useGuestContext} from './GuestPage'
import {Link} from 'react-router-dom'
import GuestCheckinInfo from '../checkin/GuestCheckinInfo'
import GuestCheckinStatus from '../checkin/GuestCheckinStatus'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLocationDot} from '@fortawesome/free-solid-svg-icons'
import numeral from 'numeral'
import HotelHero from '../../assets/hotel-hero.png'

function formatGreeting(timestamp: Date): string {
  const time = timestamp.getHours()
  if (time < 12) {
    return `Good morning`
  } else if (time >= 12 && time < 17) {
    return `Good afternoon`
  } else if (time >= 17) {
    return `Good evening`
  } else {
    return `Good day`
  }
}

function formatDate(s: string): string {
  if (!s) {
    return '-'
  }

  try {
    return format(parseISO(s), 'MMM d, yyyy')
  } catch (e) {
    console.warn(`Invalid date: ${s}`)
    return '-'
  }
}

function formatBalance(x: number | string): string {
  if (!x) {
    return '-'
  }

  try {
    const n = typeof x === 'string' ? Math.abs(parseFloat(x)) : Math.abs(x)
    return numeral(n).format('$0,0.00')
  } catch (e) {
    return '-'
  }
}

const GuestReservationInfoPage = () => {
  const {user, reservation, checkinInfo} = useGuestContext()

  const now = new Date()
  const greeting = formatGreeting(now)
  const guestName = user?.firstName || 'Guest'

  const checkinDate = reservation?.arrival
  const checkoutDate = reservation?.departure
  const checkinTime = '3:00pm'
  const checkoutTime = '11:00am'

  return (
    <div className="px-8 py-4">
      <h2 className="header-2">
        {greeting}, <span className="font-bold">{guestName}</span>
      </h2>

      <GuestCheckinStatus
        checkinDate={checkinDate}
        checkoutDate={checkoutDate}
        checkoutTime={checkoutTime}
        checkinStatus={reservation?.status}
        verificationStatus={checkinInfo?.autohostVerificationStatus}
        verificationUrl={checkinInfo?.autohostVerificationUrl}
        hasPin={true}
      />

      <div className="mb-4">
        <div className="flex flex-row items-center gap-2">
          <div className="text-2xl font-semibold">
            {reservation?.property?.name}
          </div>
          <a
            className="btn btn-circle btn-sm btn-secondary"
            target="_blank"
            href="https://maps.google.com"
          >
            <FontAwesomeIcon icon={faLocationDot} />
          </a>
        </div>
        <div>123 Main Street, Washington DC, 12345</div>
      </div>

      <div className="flex gap-4">
        <div className="w-1/3 flex flex-col gap-4 bg-linen p-4 rounded-lg">
          <div className="flex flex-col">
            <span className="uppercase font-bold text-xs text-cabana tracking-widest">
              Confirmation #:
            </span>
            <span className="text-md font-semibold">{reservation?.id}</span>
          </div>

          <div className="flex flex-col">
            <span className="uppercase font-bold text-xs text-cabana tracking-widest">
              Status:
            </span>
            <span className="text-md font-semibold">{reservation?.status}</span>
          </div>

          <div className="flex flex-col">
            <span className="uppercase font-bold text-xs text-cabana tracking-widest">
              Room Details:
            </span>
            <span className="text-md font-semibold">
              {reservation?.unitGroup?.name}
            </span>
          </div>
        </div>

        <div className="w-1/3 bg-linen p-4 rounded-lg">
          <GuestCheckinInfo
            checkinDate={checkinDate}
            checkinTime={checkinTime}
            checkoutDate={checkoutDate}
            checkoutTime={checkoutTime}
          />
        </div>
        <div className="w-1/3 bg-linen p-4 rounded-lg">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col items-left">
              <span className="uppercase font-bold text-xs text-cabana tracking-widest">
                Balance:
              </span>
              <span className="text-md font-semibold">
                {formatBalance(reservation?.balance?.amount)}
              </span>
            </div>

            <div className="flex flex-col items-left">
              <span className="uppercase font-bold text-xs text-cabana tracking-widest">
                Cancellation Policy:
              </span>
              <span className="text-md">
                {reservation?.cancellationFee?.description}
              </span>
              <Link
                to="/guest/cancellation"
                className="text-xs link text-error"
              >
                cancel reservation
              </Link>
            </div>

            <div className="flex flex-col items-left">
              <span className="uppercase font-bold text-xs text-cabana tracking-widest">
                No-Show Policy:
              </span>
              <span className="text-md">
                {reservation?.noShowFee?.description}
              </span>
            </div>
          </div>
        </div>
      </div>

      <img
        src={HotelHero}
        className="h-52 w-full rounded-lg mt-8 object-cover"
      />

      <div tabIndex={0} className="collapse collapse-arrow bg-linen mt-8">
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-xl font-medium">
          Prepare for arrival
        </div>
        <div className="collapse-content">
          <p>Arrival information goes here!</p>
        </div>
      </div>

      <div tabIndex={0} className="collapse collapse-arrow bg-linen mt-4">
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-xl font-medium">
          Payments & charges
        </div>
        <div className="collapse-content">
          <p>Payment and charge information goes here!</p>
        </div>
      </div>

      <div tabIndex={0} className="collapse collapse-arrow bg-linen mt-4 mb-16">
        <input type="checkbox" className="peer" />
        <div className="collapse-title text-xl font-medium">House rules</div>
        <div className="collapse-content">
          <p>House rules information goes here!</p>
        </div>
      </div>
    </div>
  )
}

export default GuestReservationInfoPage
